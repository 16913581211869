import React from "react"

import Heading from "@kiwicom/orbit-components/lib/Heading"
import List, { ListItem } from "@kiwicom/orbit-components/lib/List"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Mobile Phone Policy">
    <Hero title="Mobile Phone Policy" />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection>
          <Stack>
            <Text>
              Mobile phones must not be used whilst driving and should be kept
              out of sight to avoid any potential distraction from notifications
              or other alerts. Using a mobile phone whilst driving, even with a
              handsfree device, will distract your attention from the road and
              even a two second distraction can lead to a fatal incident.
            </Text>
            <Text>
              Bluetooth or other hands-free devices and headsets must not be
              used while driving for any purpose, including telephone
              conversations, listening to music or listening to the radio. This
              applies when driving any Ember vehicle, in or out of service, and
              is enforced at all times.
            </Text>
            <Text>
              If you do need to use your phone for some reason, such as to
              inform us of an incident or provide an update, then the vehicle
              must be in park and you should physically leave the drivers seat.
              You can do this at a bus stop or in another safe stopping area.
            </Text>
            <Text>
              You should not be using your phone for personal matters unless you
              are on break or you are parked, there are no passengers around and
              you are running to schedule. If you need to respond to a personal
              emergency then you should always be stopped in a safe place and
              inform the operations team of the reason for this.
            </Text>
            <Text>
              Remember we have GPS and CCTV, so we can usually see what's
              happening happening on the road. It's therefore not usually
              necessary to send photos or videos of an incident.
            </Text>
            <Text>
              If we need to contact you during a trip then we will send a
              message via the driver app. If the messages asks you to call in
              you should make sure you have followed the rules above before
              making the call.
            </Text>
            <Heading type="title3">Exceptions</Heading>
            <Text>
              There are a couple of very rare exceptions to this policy:
            </Text>
            <List>
              <ListItem>
                Navigation: If there is a diversion where you don't know the
                route and it isn't clearly signposted, then you may use your
                phone for navigation (although it's generally better to use the
                iPad). It must be in a holder and must only be used for
                navigation. When using your phone to set up the navigation you
                must be stopped somewhere safe and with the vehicle in park.
              </ListItem>
              <ListItem>
                Extreme Road Situations: These may include highly disruptive
                events where you are effectively stopped on the carriageway.
                Examples include a complete road closure where you may need to
                do a three point turn, a crash right in front of you which has
                blocked the entire road, or being stuck in snow behind a
                jackknifed lorry. In each of these situations it would be
                acceptable to use your phone to call the driver number or the
                police once the vehicle is in park and you have left your seat,
                despite still being on the carriageway.
              </ListItem>
            </List>
            <Text>
              Being stuck in heavy or slow moving traffic is NOT an exception
              and does not require you to use your phone to send an update. In
              that situation you could send an update from the next bus stop
              once you are parked but remember we already have GPS tracking so
              can see your progress. In other words, it's generally not
              necessary and it's better not to lose further time.
            </Text>
            <Heading type="title3">Three Strikes Policy</Heading>
            <Text>
              We will actively monitor phone usage. If you are using your phone
              when you shouldn't be you will receive a formal warning and a
              strike. Three strikes in any 12 month period will result in
              automatic dismissal. We really don’t want this to be a reason for
              dismissing anyone so please just follow this policy.
            </Text>
            <Text>
              Note that in case of an infringement that presents a clear risk or
              demonstrates wilful neglicence, you could be dismissed without
              “three strikes”. This could include using your phone whilst the
              bus is actually moving.
            </Text>
            <Text>
              You should also be aware that regardless of this policy you are
              responsible for following the law at all times. If you are caught
              using a mobile phone, you may receive a significant fine and
              penalty points. The penalty points may mean that we have to
              terminate your employment for insurance reasons.
            </Text>
          </Stack>
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
